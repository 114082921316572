import React from 'react'
import { useTheme } from 'styled-components'
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  LabelList,
} from 'recharts'
import { useTranslation } from 'react-i18next'

import { formatNumber } from 'src/utils'

import { Chart } from '../styles'
import { ValueType } from 'recharts/types/component/DefaultTooltipContent'

export interface DashboardBarChartProps {
  data: UncDashboard.ComposedTypes.DashboardDataValues[]
  title: string
}

export const DashboardBarChart: React.FC<DashboardBarChartProps> = ({
  data,
  title,
}) => {
  const { t } = useTranslation('Glossary')
  const theme = useTheme()

  function handleFormatValues2<TValue extends ValueType>(value: TValue) {
    return `R$ ${formatNumber(Number(value), { defaultValue: String(value) })}`
  }

  const handleFormatValues = (value: number) => {
    return `R$ ${formatNumber(value, { defaultValue: String(value) })}`
  }

  return (
    <Chart title={title}>
      <ResponsiveContainer minHeight="30rem">
        <BarChart
          data={data}
          margin={{ bottom: 0, left: 80, right: 8, top: 16 }}
        >
          <XAxis dataKey="serie_nome" />
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip formatter={handleFormatValues2} />
          <YAxis tickFormatter={handleFormatValues} />

          <Bar name={t('value')} dataKey="serie_valor" fill={theme.accent}>
            <LabelList
              dataKey="serie_valor"
              position="top"
              // formatter={handleFormatValues}
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </Chart>
  )
}
