import React, { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { FiHexagon } from 'react-icons/fi'
import { useTranslation } from 'react-i18next'

import { useIsLoading, useTypedSelector } from 'src/hooks'
import { DefaultLabel, ReactSelectOption, SnowSelect } from 'src/components'
import { setSelectedDashboard } from 'src/store/ducks/dashboardScreen'
import {
  DASHBOARD_TYPE_TYPES,
  requestFetchDashboardTypes,
  setDashboardTypesList,
} from 'src/store/ducks/dashboardType'

import { Container, Label, StyledInput } from './styles'

const Settings: React.FC = () => {
  const { t } = useTranslation(['Dashboard', 'DashboardScreen', 'Glossary'])
  const dispatch = useDispatch()

  const isLoadingScreens = useIsLoading(
    DASHBOARD_TYPE_TYPES.REQUEST_FETCH_DASHBOARD_TYPE,
  )

  const selectedDashboard = useTypedSelector(
    ({ DashboardScreen }) => DashboardScreen.selectedDashboard,
  )

  const dashboardTypes = useTypedSelector(
    ({ DashboardType }) => DashboardType.dashboardTypes,
  )

  const dashboardTypeOptions: ReactSelectOption[] = useMemo(() => {
    const dashboardOption = dashboardTypes.map(
      ({ dash_descricao, dash_codigo }) => ({
        label: dash_descricao,
        value: String(dash_codigo),
      }),
    )
    if (isLoadingScreens) {
      dashboardOption.push({
        label: t('showAllDashboard'),
        value: '',
      })
    }

    return dashboardOption
  }, [dashboardTypes, t])

  const handleChangeDashboard = (option: any) => {
    const DashboardOption: ReactSelectOption = option
    dispatch(setSelectedDashboard(DashboardOption))
  }

  useEffect(() => {
    dispatch(requestFetchDashboardTypes())
    return () => {
      dispatch(setDashboardTypesList([]))
    }
  }, [dispatch])

  return (
    <Container>
      <Label>{t('dashboardSettingsLabel')}</Label>

      <StyledInput
        labelComponent={
          <DefaultLabel>
            <FiHexagon />
            <span>{t('dashboardTypesLabel')}</span>
          </DefaultLabel>
        }
        inputComponent={
          <SnowSelect
            id="dashboardType"
            name="dashboardType"
            options={dashboardTypeOptions}
            value={selectedDashboard}
            isLoading={isLoadingScreens}
            onChange={handleChangeDashboard}
            loadingMessage={() => t('Glossary:loading')}
            placeholder={t('dashboardTypesPh')}
            noOptionsMessage={() => t('noDashboardFound')}
          />
        }
      />
    </Container>
  )
}

export default Settings
